<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Turno</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <TurnoForm></TurnoForm>
    </div>
  </v-container>
</template>
<script>
import TurnoForm from "../components/TurnoForm.vue";

export default {
  name: "TurnoAlta",
  components: {
    TurnoForm,
  },
  data() {
    return {
      ingresos: null,
      datosDni: {},
    };
  },
  created() {},
};
</script>
