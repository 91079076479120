<template>
  <v-autocomplete
    v-model="selHorario"
    :items="horarios"
    item-text="__str__"
    item-value="id"
    label="Horario"
    cache-items
    clearable
    :disabled="desactivado"
    :loading="loading"
    :search-input.sync="searchHorario"
    :rules="requerido ? this.horarioRules : []"
    no-data-text="No se encontraron resultados"
  >
    <template v-slot:no-data>
      <v-btn
        x-small
        type="submit"
        color="primary"
        @click="crearHorario"
        v-if="validarHorario()"
      >
        Crear: {{ searchHorario }}</v-btn
      >
    </template>
  </v-autocomplete>
</template>

<script>
import PresentismoService from "@/services/PresentismoService";

export default {
  name: "HorarioAutocomplete",
  components: {},

  data() {
    return {
      selHorario: null,
      searchHorario: null,
      horarios: [],
      loading: false,
      horarioRules: [
        (v) =>
          !!v ||
          'Debe seleccionar un elemento o ingresar un horario en formato "HH:MM - HH:MM"',
      ],
    };
  },
  props: {
    horarioGuardado: {
      type: Object,
      required: false,
    },
    desactivado: {
      type: Boolean,
      required: true,
    },
    requerido: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    if (this.horarioGuardado) {
      this.selHorario = this.horarioGuardado;
      this.horarios.push(this.horarioGuardado);
    }
  },
  methods: {
    validarHorario() {
      return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] - ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
        this.searchHorario
      );
    },

    crearHorario() {
      this.loading = true;
      let horarioInstance = {
        desde: this.searchHorario.split(" - ")[0],
        hasta: this.searchHorario.split(" - ")[1],
      };
      PresentismoService.postHorario(horarioInstance)
        .then((response) => {
          this.horarios.push(response.data);
          this.loading = false;
          this.searchHorario = response.data.__str__;
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
        });
    },

    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;

      PresentismoService.getHorarios(v)
        .then((response) => {
          this.horarios = response.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    searchHorario(val) {
      val && val !== this.selHorario && this.querySelections(val);
    },
  },
};
</script>
