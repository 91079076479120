<template>
  <v-card>
    <v-card-title>Datos Principales</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              :items="tipos"
              item-text="nombre"
              item-value="id"
              label="Tipo Turno"
              v-model="tipo"
              :onchange="hideShiftControls()"
            ></v-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="nombre"
              :counter="150"
              label="Nombre"
              required
              :rules="nombreRules"
              @input="nombre = nombre.toUpperCase()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-textarea
              name="input-7-1"
              auto-grow
              rows="1"
              label="Descripción"
              v-model="descripcion"
              hint="Ingrese una descripción sobre el turno."
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-label>Color</v-label>
            <v-color-picker
              v-model="color"
              dot-size="25"
              hide-canvas
              hide-inputs
              hide-alpha
              hide-mode-switch
              hide-sliders
              mode="hexa"
              show-swatches
              swatches-max-height="80"
              :rules="selectRules"
            ></v-color-picker>
          </v-col>

          <v-col cols="12" md="4">
            <div v-if="showFeriadoControls">
              <v-select
                :items="incluyeferiados"
                item-text="nombre"
                item-value="id"
                label="Incluye Feriados?"
                v-model="incluyeferiado"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div v-if="showFeriadoControls">
              <HorarioAutocomplete
                ref="horarioFeriado"
                :desactivado="this.incluyeferiado == 1 ? true : false"
                :requerido="
                  this.showFeriadoControls && this.incluyeferiado != '1'
                    ? true
                    : false
                "
              >
              </HorarioAutocomplete>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-switch
              v-model="estado"
              :label="estado ? 'Estado: Activado' : 'Estado: Desactivado'"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <div v-if="showSimpleControls">
              <HorarioAutocomplete
                ref="horarioSimple"
                :requerido="true"
                :desactivado="false"
              >
              </HorarioAutocomplete>
            </div>
          </v-col>
        </v-row>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/turnos"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import PresentismoService from "../services/PresentismoService";
import HorarioAutocomplete from "../components/autocompletes/HorarioAutocomplete.vue";

export default {
  name: "TurnoForm",
  components: {
    HorarioAutocomplete,
  },

  data() {
    return {
      tipos: [],
      tipo: 1,
      incluyeferiados: [],
      incluyeferiado: 1,
      formValidity: false,
      nombre: "",
      descripcion: "",
      color: "#F44336",
      error: false,
      menuFecha: false,
      estado: true,
      loadingSave: false,
      nombreRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 150 ||
          "La longuitud del campo nombre debe ser menor o igual a 150 caracteres.",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      showFeriadoControls: true,
      showSimpleControls: true,
    };
  },
  props: {
    turno: {
      type: Object,
      required: false,
    },
  },
  created() {
    PresentismoService.getTurnosTipos()
      .then((response) => {
        this.tipos = response.data;
      })
      .catch((error) => console.log(error));
    PresentismoService.getIncluyeFeriados()
      .then((response) => {
        this.incluyeferiados = response.data;
      })
      .catch((error) => console.log(error));

    // if (this.feriado) {
    //   this.tipo = this.feriado.tipo;
    //   this.nombre = this.feriado.nombre;
    //   this.fecha = this.feriado.fecha;
    //   this.repite_anual = this.feriado.repite_anual == "Sí" ? true : false;
    // }
  },

  methods: {
    onSubmit() {
      this.loadingSave = true;

      let turnoInstance = {
        tipo: this.tipo,
        nombre: this.nombre,
        descripcion: this.descripcion,
        color: this.color,
        horario_simple:
          this.tipo == "1"
            ? this.turno
              ? this.$refs.horarioSimple.selHorario.id
              : this.$refs.horarioSimple.selHorario
            : null,
        incluye_feriados: this.incluyeferiado,
        horario_feriado:
          this.incluyeferiado != "1"
            ? this.turno
              ? this.$refs.horarioFeriado.selHorario.id
              : this.$refs.horarioFeriado.selHorario
            : null,
        estado: this.estado,
      };

      if (this.turno) {
        turnoInstance.id = this.turno.id;
        PresentismoService.putTurno(turnoInstance)
          .then((response) => {
            this.$router.push({
              name: "FeriadoDetalle", // Actualizar esto con el detalle del turno
              params: { id: turnoInstance.id },
            });
          })
          .catch((error) => {
            this.error = error.response.data;
            this.loadingSave = false;
          });
      } else {
        PresentismoService.postTurno(turnoInstance)
          .then((response) => {
            this.$router.push({
              name: "Turnos",
            });
          })
          .catch((error) => {
            this.error = error;
            this.loadingSave = false;
          });
      }
    },

    hideShiftControls() {
      if (this.tipo == "5") {
        // sin turno
        this.showFeriadoControls = false;
        this.showSimpleControls = false;
      } else if (this.tipo == "1") {
        // simple
        this.showFeriadoControls = true;
        this.showSimpleControls = true;
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
